.employee-card {
    display: flex;
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    border: 1px solid var(--tertiary);
    border-radius: 50px;
    gap: var(--space);
    
}

.employee-card .avatar {
    width: 30%;
    border-radius: 50px;
}

.employee-card .details {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left !important;
}

.employee-card .details .name {
    font-family: var(--font-bold);
    font-size: 1.2em;
    margin-top: calc(var(--space)/3);
}

.details span.team {
    padding: 2px;
    font-size: 0.8em;
}
