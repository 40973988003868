.main-canvas {
    width: 70%;
    height: 100%;
    color: var(--ternary);
    background-image: url('../../assets/grid.svg');
    background-size: cover;
    overflow-x: auto;
    overflow-y: auto;
}










