.container {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  overflow-y: hidden;
}

.container main {
  display: flex;
  justify-content: space-around;
  height: calc(100dvh - 50px);
}

