* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Poppins-regular";
  src: url('./assets/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Poppins-semibold";
  src: url('./assets/Poppins-SemiBold.ttf') format('truetype');
}

body {
  font-family: "Poppins-regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primary: #222831;
  --secondary: #393E46;
  --tertiary: #00ADB5;
  --ternary: #EEEEEE;

  --font-regular: "Poppins-regular";
  --font-bold: "Poppins-semibold";
  --box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  --space: 1em;

  font-size: 16px;
  color: var(--ternary);

}


