.nav-bar {
    width: 100%;
    height: 50px;
    background-color: var(--tertiary);
    color: white;
    font-family: var(--font-bold);
    font-weight: 500;
    display: flex;
    padding-left: var(--space);
    padding-right: var(--space);
}