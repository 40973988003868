.side-bar {
    width: 30%;
    background-color: var(--primary);
    height: 100%;
    color: var(--ternary);
    
    padding-top: var(--space);
    padding-bottom: var(--space);
    
}

.side-bar .filters {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: var(--space);
    align-items: center;
    padding-bottom: var(--space);
    margin-left: var(--space);
    margin-right: var(--space);
    border-bottom: 2px solid #ccc;

}